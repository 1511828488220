/*
 *********************************TEST W/INMATE COGNITO CREDENTIALS***************************************
 */
const aws_mobile = {
	API: {
		graphql_endpoint: "https://yuhz6ow245ej3g27sr6pct3e6e.appsync-api.us-east-2.amazonaws.com/graphql"
	},
	aws_appsync_authenticationType: "AWS_COGNITO_USER_POOLS",
	Auth: {
		region: "us-east-2",
		userPoolId: "us-east-2_xYUImXaXS",
		identityPoolId: "us-east-2:e7ab444a-e626-4192-96d1-6a49a638bed4",
		userPoolWebClientId: "m9emj5ck7eq5ac7bpr6brp3vi",
		cognitoDomain: "https://kiwiauth2.auth.us-east-2.amazoncognito.com",
	},
	Storage: {
		messageImgs: {
			bucket: "kiwichatappstack-kiwichatmsgimgsaa0ddb06-1e9ff7jxl2bc8",
			region: "us-east-2",
		},
		grievanceAndForm: {
			bucket: "kiwichatappstack-kiwichatgrievanceandformfilesed8-1oe7bmz3i36j8",
			region: "us-east-2",
		},
		bulletins: {
			bucket: "kiwichatappstack-kiwichatbulletinfilesfea9a418-639dgksouqey",
			region: "us-east-2",
		},
	},
	kiwi: {
		refresh_token: "",
		logLevel: 'DEBUG',
		clientType: 'INMATE_ANDROID',
	},
	environment: {
		env: "TEST"
	},
};

export default aws_mobile;